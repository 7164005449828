import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";

const Inline = ({ children }) => {
  return (
    <Highlight
      {...defaultProps}
      code={children}
      language="markdown"
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <code
          className={className}
          style={{
            ...style,
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            borderRadius: "0.25rem",
          }}
        >
          {tokens.map((line, i) => (
            <span key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </span>
          ))}
        </code>
      )}
    </Highlight>
  );
};

export default Inline;
