import React from "react";
import { MDXProvider } from "@mdx-js/react";
import Playground from "../components/playground";
import Inline from "../components/inline";

import Code from "../components/code";
import NoteCard from "../components/notecard";

const components = {
  pre: props => {
    if (props.children.props["react-live"]) {
      return <Playground {...props} />;
    } else {
      return <Code {...props} />;
    }
  },
  inlineCode: props => {
    return <Inline {...props} />;
  },
  wrapper: ({ children }) => <>{children}</>,
  NoteCard,
};

export const wrapRootElement = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
