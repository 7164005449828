import React from "react";
import styled from "styled-components";
import { COLORS } from "../utils/constants";
import Icon from "./Icon";
import VisuallyHidden from "./VisuallyHidden";

const NoteCard = ({ variant = "info", children }) => {
  const styles = {
    info: {
      "--variant-color": COLORS.info,
      "--background-color": COLORS.infoBackground,
    },
    success: {
      "--variant-color": COLORS.success,
      "--background-color": COLORS.successBackground,
    },
    warning: {
      "--variant-color": COLORS.warning,
      "--background-color": COLORS.warningBackground,
    },

    danger: {
      "--variant-color": COLORS.error,
      "--background-color": COLORS.errorBackground,
    },
  };
  return (
    <StyledAside style={styles[variant]}>
      <VisuallyHidden>{variant}</VisuallyHidden>
      <IconWrapper>
        <Icon id={variant} size={32} />
      </IconWrapper>
      {children}
    </StyledAside>
  );
};

const StyledAside = styled.aside`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 1rem;
  padding-top: 24px;
  border-left: 3px solid var(--variant-color);
  border-radius: 0px 6px 6px 0px;
  background-color: var(--background-color);

  p {
    margin-bottom: 1rem;
  }
  *:last-child {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  color: var(--variant-color);
  background-color: var(--color-background);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  left: 0;
  padding: 8px;
  border-radius: 50%;
`;

export default NoteCard;
