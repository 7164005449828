// custom typefaces
import "typeface-montserrat";
import "typeface-merriweather";
import "typeface-inter"
import "@fontsource/inter";
import "@fontsource/crimson-pro";
// normalize CSS across browsers
import "./src/normalize.css";
// custom CSS styles
import "./src/style.css";

import { wrapRootElement as wrap } from "./src/utils/wrap-root-element";

export const wrapRootElement = wrap;
