export const COLORS = {
    primary: 'hsl(240deg 80% 60%)',
    info: 'hsl(245deg,100%,60%)',
    infoBackground: 'hsl(210deg,55%,92%)',
    success: 'hsl(145deg,100%,27%)',
    successBackground: 'hsla(145deg,100%,40%,0.1)',
    warning: 'hsl(37deg,100%,50%)',
    warningBackground: 'hsla(52deg,100%,50%,0.25)',
    error: 'hsl(340deg,95%,46%)',
    errorBackground: 'hsla(340deg,95%,43%,0.1)',

    gray50: 'hsl(197deg 0% 95%)',
    gray100: 'hsl(197deg,25%,95%)',
    gray200: 'hsl(197deg,16%,90%)',
    gray300: 'hsl(197deg,8%,80%);',
    gray400: 'hsl(197deg,8%,70%);',
    gray500: 'hsl(197deg,7%,60%)',
    gray600: 'hsl(197deg,15%,50%)',
    gray700: 'hsl(197deg,12%,40%)',
    gray800: 'hsl(197deg,20%,30%)',
    gray900: 'hsl(197deg,25%,20%)',
    gray1000: 'hsl(197deg,15%,15%)',
    black: 'hsl(0deg 0% 0%)',
  };