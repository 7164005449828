import React from "react";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import theme from "prism-react-renderer/themes/nightOwl";

const Playground = props => (
  <LiveProvider code={props.children.props.children.trim()} theme={theme}>
    <LiveEditor />
    <LiveError />
    <LivePreview />
  </LiveProvider>
);

export default Playground;
